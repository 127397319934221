import React from 'react';

import { ProductInfo } from '@t/components';
import Application from '@/components/main/application/Application';

interface ApplicationSectionProps {
  items: ProductInfo[];
}

const ApplicationSection: React.FC<ApplicationSectionProps> = ({ items }) => (
  <>
    {items.map((item, index) => (
      <Application item={item} index={index} key={item.id} />
    ))}
  </>
);

export default ApplicationSection;
