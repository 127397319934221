import React from 'react';
import styled from 'styled-components';

import { Link } from '@/components/common/Link';
import createGA from '@/components/common/createGA';

// TODO : 4.1 업데이트 시 적용 예정
// type Url = '/fe-guide/' | '/weekly-pick/';
//
// type Language = 'ko' | 'en';
//
// type UrlLink = `${Url} ${Language}`;

interface SeeAllLinkProps {
  url: '/fe-guide/ko' | '/fe-guide/en' | '/posts/ko' | '/posts/en';
}

const LinkContent = styled.span`
  color: #526fff;

  &:hover {
    text-decoration: underline;
  }
`;

const SeeAllLink: React.FC<SeeAllLinkProps> = ({ url }) => {
  const ga = createGA('Main - see all(FE Guide, Posts', 'click', 'See all link', url);

  return (
    <Link type="internal" url={url} ga={ga}>
      <LinkContent>see all</LinkContent>
    </Link>
  );
};

export default SeeAllLink;
