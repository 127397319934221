import React from 'react';
import styled from 'styled-components';

import { ButtonItem } from '@t/components';
import { InlineBlockLink } from '@/components/common/Link';

interface SeeMoreButtonProps {
  buttonItem: ButtonItem;
}

interface SeeMoreProps {
  color: string;
}

const SeeMore = styled.span<SeeMoreProps>`
  display: inline-block;
  width: 160px;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  line-height: 50px;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  color: ${({ color }): string => color};
  border: ${({ color }): string => `2px solid ${color}`};

  &:hover,
  &:active {
    color: #fff;
    background-color: ${({ color }): string => color};
  }

  @media (max-width: 1200px) {
    width: 120px;
    height: 38px;
    line-height: 38px;
    border-radius: 4px;
    font-size: 13px;
  }

  @media (max-width: 720px) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MobileWrapper = styled.div`
  display: none;

  @media (max-width: 720px) {
    display: block;
    text-align: center;
  }
`;

export const SeeMoreButton: React.FC<SeeMoreButtonProps> = ({
  buttonItem: { url, color, ga, testId },
}) => (
  <InlineBlockLink type="internal" url={url} ga={ga} testId={testId} className="see-more">
    <SeeMore color={color ?? '#515ce6'}>See more</SeeMore>
  </InlineBlockLink>
);

export const MobileSeeMoreButton: React.FC<SeeMoreButtonProps> = ({ buttonItem }) => (
  <MobileWrapper>
    <SeeMoreButton buttonItem={{ ...buttonItem, color: '#515ce6' }} />
  </MobileWrapper>
);
