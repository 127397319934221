import React from 'react';
import styled from 'styled-components';

import { ApplicationId } from '@t/components';

interface PreviewProps {
  id: ApplicationId;
}

interface ImageProps {
  id: ApplicationId;
  src: string;
}

const webPreviewHeightMap = {
  'tui-chart': 45,
  'tui-editor': 43,
  'tui-grid': 41,
  'tui-calendar': 44,
  'tui-image-editor': 42,
};

const imageAreaHeightMap = {
  'tui-chart': 69,
  'tui-editor': 66,
  'tui-grid': 62.5,
  'tui-calendar': 67.4,
  'tui-image-editor': 64.5,
};

const PreviewArea = styled.div`
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: solid 1px #ddd;
  border-radius: 2px;
`;

const WebPreviewArea = styled(PreviewArea)<PreviewProps>`
  width: 61.5%;
  height: 0;
  position: relative;
  padding-bottom: ${({ id }) => `${webPreviewHeightMap[id]}%`};

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobilePreviewArea = styled(PreviewArea)`
  display: none;

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 30px;
  }

  @media (max-width: 720px) {
    margin-bottom: 30px;
  }
`;

const Header = styled.div`
  height: 22px;
  background-color: #e5e5e5;
  padding-top: 8px;
  text-align: left;

  @media (max-width: 1200px) {
    height: 23px;
    padding-top: 6px;
  }

  @media (max-width: 720px) {
    height: 11px;
    padding-top: 4px;
    display: flex;
  }
`;

const HeaderDecoration = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #eee;
  margin-left: 8px;

  @media (max-width: 1200px) {
    width: 11px;
    height: 11px;
  }

  @media (max-width: 720px) {
    width: 7px;
    height: 7px;
  }
`;

const ImageArea = styled.div<PreviewProps>`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: ${({ id }) => `${imageAreaHeightMap[id]}%`};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
  }

  @media (max-width: 720px) {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
`;

const Preview: React.FC<ImageProps> = ({ id, src }) => {
  const url = `https://uicdn.toast.com/toastui/img/${src}.png`;

  return (
    <>
      <Header>
        <HeaderDecoration />
        <HeaderDecoration />
        <HeaderDecoration />
      </Header>
      <ImageArea id={id}>
        <ImageWrapper>
          <Image src={url} />
        </ImageWrapper>
      </ImageArea>
    </>
  );
};

export const WebPreview: React.FC<PreviewProps> = ({ id }) => (
  <WebPreviewArea id={id}>
    <Preview id={id} src={id} />
  </WebPreviewArea>
);

export const MobilePreview: React.FC<PreviewProps> = ({ id }) => (
  <MobilePreviewArea>
    <Preview id={id} src={`${id}_mobile`} />
  </MobilePreviewArea>
);
