import React from 'react';
import styled from 'styled-components';

import { ReferenceItem } from '@t/components';
import { ReferenceBI } from '@t/styles';
import { InlineBlockLink } from '@/components/common/Link';
import createGA from '@/components/common/createGA';
import { Icon } from '@/components/common/Icon';

const ReferenceIconWidth: ReferenceIconWidthProps = {
  1920: {
    NHN_CLOUD_BI: 204,
    TOAST_FILE_BI: 196,
    DOORAY_BI: 238,
    NCP_BI: 145,
  },
  1200: {
    NHN_CLOUD_BI: 153,
    TOAST_FILE_BI: 147,
    DOORAY_BI: 179,
    NCP_BI: 109,
  },
};

interface ReferenceSectionProps {
  items: ReferenceItem[];
}

interface ReferenceProps {
  item: ReferenceItem;
}

interface ReferenceBIProps {
  iconType: ReferenceBI;
}

interface ReferenceIconWidthProps {
  1920: {
    [key in ReferenceBI]: number;
  };
  1200: {
    [key in ReferenceBI]: number;
  };
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  background-color: #deeeff;

  @media (max-width: 720px) {
    display: none;
  }
`;

const ReferenceTitle = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 1.91;
  color: #222;
  margin-bottom: 30px;

  @media (max-width: 1200px) {
    font-size: 16px;
  }
`;

const ReferenceIconArea = styled.div`
  & a {
    margin: 0 25px;
  }

  @media (max-width: 1200px) {
    & a {
      margin: 0 19px;
    }
  }
`;

const ReferenceIcon = styled(Icon)<ReferenceBIProps>`
  width: ${({ iconType }) => `${ReferenceIconWidth[1920][iconType]}px`};
  height: 75px;
  background-size: 1350px;

  &:hover {
    background-position-y: 46.4%;
  }

  @media (max-width: 1200px) {
    width: ${({ iconType }) => `${ReferenceIconWidth[1200][iconType]}px`};
    height: 56px;
    background-size: 1000px;
  }
`;

const Reference: React.FC<ReferenceProps> = ({ item: { link, name } }) => {
  const ga = createGA('Main - Reference', 'click', 'Reference logo', name);
  const iconType = name.toUpperCase().replace(' ', '_').concat('_BI') as ReferenceBI;

  return (
    <InlineBlockLink type="external" url={link} ga={ga} testId="reference-list">
      <ReferenceIcon iconType={iconType} />
    </InlineBlockLink>
  );
};

const ReferenceSection: React.FC<ReferenceSectionProps> = ({ items }) => (
  <Wrapper>
    <ReferenceTitle>Who's using TOAST UI?</ReferenceTitle>
    <ReferenceIconArea>
      {items.map((item, index) => (
        <Reference item={item} key={index} />
      ))}
    </ReferenceIconArea>
  </Wrapper>
);

export default ReferenceSection;
