import React from 'react';
import styled from 'styled-components';

import { ComponentIconItem } from '@t/components';
import { IconType } from '@t/styles';
import { BlockLink } from '@/components/common/Link';
import { List, ListItem } from '@/components/common/List';
import { Icon } from '@/components/common/Icon';
import createGA from '@/components/common/createGA';

interface IconsProps {
  iconItems: ComponentIconItem[];
}

const IconArea = styled(List)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 650px;

  @media (max-width: 768px) {
    width: 560px;
    margin-top: 50px;
  }
`;

const Component = styled(ListItem)`
  width: 100px;
  height: 90px;
  text-align: center;
  margin: 25px;

  &:hover h3 {
    color: #515ce6;
  }

  &:hover i {
    background-position-y: 21.25%;
  }

  @media (max-width: 1200px) {
    margin: 20px;
  }
`;

const ComponentIcon = styled(Icon)`
  width: 70px;
  height: 70px;

  @media (max-width: 1200px) {
    width: 50px;
    height: 50px;
  }
`;

const Name = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: #222;
  margin: 0;

  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

const Icons: React.FC<IconsProps> = ({ iconItems }) => {
  const ga = createGA('Main - component', 'click', 'Component icon');

  return (
    <IconArea>
      {iconItems.map(({ id, name }) => {
        const type = `${name.replace(' ', '_').toUpperCase()}_BI` as IconType;

        return (
          <Component key={id}>
            <BlockLink
              type="internal"
              url={`/${id}`}
              ga={{ ...ga, label: `Component icon - ${name}` }}
              testId="component-link-list"
            >
              <ComponentIcon iconType={type} />
              <Name>{name}</Name>
            </BlockLink>
          </Component>
        );
      })}
    </IconArea>
  );
};

export default Icons;
