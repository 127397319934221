import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { ReferenceItem, PostNode, Post } from '@t/components';
import { isKorean, createProductSectionData } from '@/util';
import SEO from '@/components/SEO';
import AnnouncementBarSection from '@/components/main/announcementBar/AnnouncementBarSection';
import HeroSection from '@/components/main/hero/HeroSection';
import ApplicationSection from '@/components/main/application/ApplicationSection';
import ComponentSection from '@/components/main/component/ComponentSection';
import ToolSection from '@/components/main/tool/ToolSection';
import DocumentSection from '@/components/main/document/DocumentSection';
import ReferenceSection from '@/components/main/reference/ReferenceSection';
import SitemapSection from '@/components/main/sitemap/SitemapSection';

import createGA from '@/components/common/createGA';

import '@/query/postQueryFragment';
import '@/query/feGuideQueryFragment';

const createPostsSectionData = ({ edges }: { edges: PostNode[] }) =>
  edges.map(({ node }) => {
    const { id, tags, title, date } = node.frontmatter;

    return { id, title, date, tag: tags[0], url: `/posts/${id}` };
  });

const createGuideSectionData = ({ edges }: { edges: PostNode[] }) =>
  edges.map(({ node }) => {
    const { title, id } = node.frontmatter;

    return { title, id, url: `/fe-guide/${id}` };
  });

const createDescription = ({ edges }: { edges: { node: Post }[] }, id: string) => {
  return edges.find(({ node }) => node.id === id)!.node.description;
};

const Index: React.FC = () => {
  const { allProductsJson, allDescriptionsJson, allPosts, allEnPosts, allGuides, allEnGuides } =
    useStaticQuery(
      graphql`
        query indexQuery {
          # query for each section
          ...allProductsJson

          # query for descriptions
          ...allDescriptionsJson

          # query for post section
          allPosts: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/(/posts/ko)/.*.md$/" } }
            limit: 4
          ) {
            edges {
              node {
                ...PostFragment
              }
            }
          }

          allEnPosts: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/(/posts/en)/.*.md$/" } }
            limit: 4
          ) {
            edges {
              node {
                ...PostFragment
              }
            }
          }

          # query for guide section
          allGuides: allMarkdownRemark(
            sort: { fields: [frontmatter___seq], order: ASC }
            filter: { fileAbsolutePath: { regex: "/(/guides/ko)/.*.md$/" } }
          ) {
            edges {
              node {
                ...feGuideFragment
              }
            }
          }

          allEnGuides: allMarkdownRemark(
            sort: { fields: [frontmatter___seq], order: ASC }
            filter: { fileAbsolutePath: { regex: "/(/guides/en)/.*.md$/" } }
          ) {
            edges {
              node {
                ...feGuideFragment
              }
            }
          }
        }
      `
    );

  const { application, component, tool, references } = createProductSectionData(allProductsJson);

  const postData = isKorean ? allPosts : allEnPosts;
  const postSectionData = createPostsSectionData(postData);

  const guideData = isKorean ? allGuides : allEnGuides;
  const guideSectionData = createGuideSectionData(guideData);

  const componentDescription = createDescription(allDescriptionsJson, 'component');
  const ga = createGA('Main - sitemap', 'click', 'Sitemap');

  return (
    <div>
      <SEO />
      <AnnouncementBarSection />
      <HeroSection />
      <ApplicationSection items={application} />
      <ComponentSection items={component} description={componentDescription} />
      <ToolSection items={tool} />
      <DocumentSection guideItems={guideSectionData} postItems={postSectionData} />
      <ReferenceSection items={references as ReferenceItem[]} />
      <SitemapSection items={{ application, component, tool }} ga={ga} />
    </div>
  );
};

export default Index;
