import React from 'react';
import styled from 'styled-components';

import { PostItem } from '@t/components';
import { isKorean } from '@/util';
import { postUrl } from '@/constant';
import Item from '@/components/main/document/PostItem';
import SeeAllLink from '@/components/main/document/SeeAllLink';
import { MobileSeeMoreButton } from '@/components/main/common/SeeMoreButton';
import createGA from '@/components/common/createGA';

interface PostProps {
  items: PostItem[];
}

const Wrapper = styled.div`
  max-width: 1200px;
  width: 62.5%;
  margin-top: 100px;

  @media (max-width: 1200px) {
    max-width: 900px;
    width: 75%;
    margin-top: 75px;
  }

  @media (max-width: 768px) {
    max-width: 555px;
    margin-top: 55px;
  }

  @media (max-width: 720px) {
    margin-top: 50px;
    width: 89%;

    & .see-more {
      position: relative;
      width: 50%;
      height: 0;
      padding-bottom: 13.6%;
    }
  }
`;

const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 50px;

  @media (max-width: 1200px) {
    margin-bottom: 45px;
  }

  @media (max-width: 720px) {
    margin: 0;
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 42px;
  font-weight: bold;
  color: #222;

  @media (max-width: 1200px) {
    font-size: 30px;
  }

  @media (max-width: 720px) {
    font-size: 21px;
  }
`;

const LinkArea = styled.div`
  width: 60px;
  font-size: 18px;
  font-weight: 500;
  text-align: end;

  @media (max-width: 1200px) {
    font-size: 14px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

const ListArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 720px) {
    margin-bottom: 40px;
  }
`;

const Post: React.FC<PostProps> = ({ items }) => {
  const postsUrl = isKorean ? postUrl.KO : postUrl.EN;
  const ga = createGA('Main - Posts', 'click', 'Posts');

  return (
    <Wrapper>
      <TitleArea>
        <Title>Posts</Title>
        <LinkArea>
          <SeeAllLink url={postsUrl} />
        </LinkArea>
      </TitleArea>
      <ListArea>
        {items.map((item) => (
          <Item item={item} key={item.id} />
        ))}
      </ListArea>
      <MobileSeeMoreButton
        buttonItem={{
          url: postsUrl,
          ga: { ...ga, label: 'Posts - Mobile more button' },
        }}
      />
    </Wrapper>
  );
};

export default Post;
