import React from 'react';
import styled from 'styled-components';

import { GuideItem, PostItem } from '@t/components';
import FeGuide from '@/components/main/document/FeGuide';
import Post from '@/components/main/document/Post';

interface DocumentSectionProps {
  guideItems: GuideItem[];
  postItems: PostItem[];
}

const Wrapper = styled.div`
  background-color: #f9f9f9;
  padding: 100px 0 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    padding: 75px 0 100px;
  }

  @media (max-width: 768px) {
    padding: 50px 0 75px;
  }

  @media (max-width: 720px) {
    padding: 8% 0 14%;
  }
`;

const DocumentSection: React.FC<DocumentSectionProps> = ({ guideItems, postItems }) => {
  return (
    <Wrapper>
      <FeGuide items={guideItems} />
      <Post items={postItems} />
    </Wrapper>
  );
};

export default DocumentSection;
