import React from 'react';
import styled from 'styled-components';

interface DateProps {
  date: string;
}

const Wrapper = styled.div`
  font-size: 16px;
  color: #777;
  text-align: end;

  @media (max-width: 1200px) {
    font-size: 12px;
  }

  @media (max-width: 720px) {
    font-size: 14px;
    text-align: start;
  }
`;

const Date: React.FC<DateProps> = ({ date }) => {
  return <Wrapper>{date}</Wrapper>;
};

export default Date;
