import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import { isKorean } from '@/util';
import { Link } from '@/components/common/Link';
import createGA from '@/components/common/createGA';

const AnnouncementBar = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222;
  font-size: 16px;
  color: #fff;
  opacity: 0.9;
  z-index: 999;

  @media (max-width: 720px) {
    height: 45px;
    font-size: 14px;
  }
`;

const Title = styled.span`
  color: #ffbc00;
`;

const Button = styled.i`
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: 18px;
  background-image: url(https://uicdn.toast.com/toastui/img/toast-ui-alarm-close-btn.png);
  background-size: cover;

  @media (max-width: 720px) {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
`;

const AnnouncementBarSection: React.FC = () => {
  const ga = createGA('Main - announcement', 'click', 'announcement');
  const [isClosed, setClosed] = useState(true);

  useEffect(() => setClosed(!!localStorage.getItem('brandsiteAnnouncement')), []);

  const closeAnnouncement = () => {
    localStorage.setItem('brandsiteAnnouncement', 'closed');
    setClosed(true);
  };

  const query = useStaticQuery(
    graphql`
      query Announcement {
        allFile(filter: { absolutePath: { regex: "/announcement/" } }) {
          edges {
            node {
              children {
                ... on AnnouncementJson {
                  isVisible
                  ko {
                    link
                    linkText
                    description
                  }
                  en {
                    link
                    linkText
                    description
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const [announcement] = query.allFile.edges[0].node.children;
  const { isVisible } = announcement;
  const { link, linkText, description } = isKorean ? announcement.ko : announcement.en;

  return isVisible && !isClosed ? (
    <AnnouncementBar>
      <Link type="external" url={link} ga={ga}>
        <Title>{linkText}</Title>
      </Link>
      &nbsp;{description}
      <Button onClick={closeAnnouncement} />
    </AnnouncementBar>
  ) : null;
};

export default AnnouncementBarSection;
