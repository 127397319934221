import React from 'react';
import styled from 'styled-components';

import { GuideItem } from '@t/components';
import { isKorean } from '@/util';
import { feGuideUrl } from '@/constant';
import { BlockLink } from '@/components/common/Link';
import SeeAllLink from '@/components/main/document/SeeAllLink';
import { MobileSeeMoreButton } from '@/components/main/common/SeeMoreButton';
import createGA from '@/components/common/createGA';

interface FeGuideProps {
  items: GuideItem[];
}

const Wrapper = styled.div`
  max-width: 1200px;
  width: 62.5%;

  @media (max-width: 1200px) {
    max-width: 900px;
    width: 75%;
  }

  @media (max-width: 768px) {
    max-width: 555px;
    width: 89%;

    & .see-more {
      position: relative;
      width: 50%;
      height: 0;
      padding-bottom: 13.6%;
    }
  }
`;

const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 40px;

  @media (max-width: 1200px) {
    margin-bottom: 30px;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 42px;
  font-weight: bold;
  color: #222;

  @media (max-width: 1200px) {
    font-size: 30px;
  }

  @media (max-width: 720px) {
    font-size: 21px;
  }
`;

const LinkArea = styled.div`
  width: 60px;
  font-size: 18px;
  font-weight: 500;
  text-align: end;

  @media (max-width: 1200px) {
    font-size: 14px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

const ListArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 720px) {
    display: none;
  }
`;

const LinkContent = styled.div`
  display: flex;
  width: calc(180px + (277 - 180) * ((100vw - 1200px) / (1920 - 1200)));
  height: 66px;
  margin: 10px 0;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  background-color: #f4f4f4;
  font-size: 18px;
  color: #222;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #fff;
    background-color: #515ce6;
  }

  @media (max-width: 1200px) {
    width: calc(130px + (206 - 130) * ((100vw - 768px) / (1200 - 768)));
    height: 50px;
    margin: 7px 0;
    border-radius: 6px;
    font-size: 13px;
  }

  @media (max-width: 768px) {
    width: 175px;
    height: 50px;
  }
`;

const MobileDescription = styled.p`
  display: none;
  margin: 0;
  font-size: 14px;
  line-height: 2;
  color: #555;
  margin-bottom: 44px;
  word-break: break-all;

  @media (max-width: 720px) {
    display: block;
  }
`;

const FeGuide: React.FC<FeGuideProps> = ({ items }) => {
  const guideUrl = isKorean ? feGuideUrl.KO : feGuideUrl.EN;
  const ga = createGA('Main - FE Guide', 'click', 'FE Guide');

  return (
    <Wrapper>
      <TitleArea>
        <Title>FE Guide</Title>
        <LinkArea>
          <SeeAllLink url={guideUrl} />
        </LinkArea>
      </TitleArea>
      <ListArea>
        {items.map(({ url, id, title }) => (
          <BlockLink
            type="internal"
            url={url}
            key={id}
            ga={{ ...ga, label: `FE Guide - ${title}` }}
            testId="guide-list"
          >
            <LinkContent>{title}</LinkContent>
          </BlockLink>
        ))}
      </ListArea>
      <MobileDescription>
        {
          'Our Front End Guides are there to help you get through your development. With our FE Guides, you have full access to our database of front end development know-hows.'
        }
      </MobileDescription>
      <MobileSeeMoreButton
        buttonItem={{ url: guideUrl, ga: { ...ga, label: 'FE Guide - Mobile more button' } }}
      />
    </Wrapper>
  );
};

export default FeGuide;
