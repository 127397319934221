import React from 'react';
import styled from 'styled-components';

import { UrlLinks } from '@t/components';
import { isKorean } from '@/util';
import { feGuideUrl, postUrl } from '@/constant';
import { Link } from '@/components/common/Link';
import createGA from '@/components/common/createGA';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 69px auto 0;
  padding: 7% 8.3% 12%;
  background-color: #fff;
  background: url(https://uicdn.toast.com/toastui/img/toast-ui-hero1920-2x.png) center/cover
    no-repeat;

  @media (max-width: 1200px) {
    padding: 7% 2.5% 12%;
    background: url(https://uicdn.toast.com/toastui/img/toast-ui-hero1200-2x.png) center/cover
      no-repeat;
  }

  @media (max-width: 768px) {
    padding: 11% 2.5% 20%;
    background: url(https://uicdn.toast.com/toastui/img/toast-ui-hero768-2x.png) center/cover
      no-repeat;
  }

  @media (max-width: 720px) {
    margin-top: 56px;
    padding: 50% 5.5%;
    background: url(https://uicdn.toast.com/toastui/img/toast-ui-hero720-2x.png) center/cover
      no-repeat;
  }
`;

const Slogan = styled.div`
  padding-bottom: 5%;
`;

const Title = styled.div`
  font-size: calc(38px + (54 - 38) * ((100vw - 1200px) / (1920 - 1200)));
  font-weight: bold;
  text-align: center;
  color: #222;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1200px) {
    font-size: calc(32px + (38 - 32) * ((100vw - 720px) / (1200 - 720)));
  }

  @media (max-width: 800px) {
    padding: 0 10%;
  }

  @media (max-width: 720px) {
    font-size: calc(28px + (30 - 28) * ((100vw - 360px) / (720 - 360)));
  }
`;

const Description = styled.div`
  font-size: calc(18px + (24 - 18) * ((100vw - 1200px) / (1920 - 1200)));
  margin-top: 25px;
  padding: 0 19%;
  text-align: center;
  color: #444;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1200px) {
    font-size: calc(15px + (18 - 15) * ((100vw - 720px) / (1200 - 720)));
    margin-top: 18px;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
  }

  @media (max-width: 720px) {
    margin-top: 3%;
    padding: 0 16%;
    font-size: calc(16px + (18 - 16) * ((100vw - 360px) / (720 - 360)));
  }
`;

const Text = styled.p`
  margin: 0;
`;

const HighlightText = styled.span`
  color: #515ce6;
`;

const LinkText = styled.span`
  color: #515ce6;
  text-decoration: underline;
`;

const MobileLinkWrapper = styled.div`
  display: none;
  width: 89%;
  margin: 0 auto;

  @media (max-width: 720px) {
    display: block;
  }
`;

const MobileLinkArea = styled.div`
  color: #555;
  background-color: #f4f4f4;
  padding: 6%;
  text-align: center;
  border-radius: 12px;
  font-size: 14px;
  line-height: 1.5;

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const urlLinks: UrlLinks = {
  'fe guide': isKorean ? feGuideUrl.KO : feGuideUrl.EN,
  post: isKorean ? postUrl.KO : postUrl.EN,
};

const MobileLink: React.FC = () => {
  const ga = createGA('Main - mobile hero link', 'click', 'link');

  return (
    <MobileLinkWrapper>
      <MobileLinkArea>
        <Text>
          We provides&nbsp;
          <Link
            type="internal"
            url={urlLinks['fe guide']}
            ga={{ ...ga, label: 'link - mobile hero fe guide link' }}
          >
            <LinkText>FE Development Guides</LinkText>
          </Link>
          &nbsp;and
          <br />
          <Link
            type="internal"
            url={urlLinks.post}
            ga={{ ...ga, label: 'link - mobile hero posts link' }}
          >
            <LinkText>Posts</LinkText>
          </Link>
          &nbsp;to make your development easier.
        </Text>
      </MobileLinkArea>
    </MobileLinkWrapper>
  );
};

const HeroSectionComponent: React.FC = () => {
  return (
    <>
      <Wrapper>
        <Slogan>
          <Title>
            <Text>
              Use&nbsp;<HighlightText>TOAST UI</HighlightText>
            </Text>
            <Text>to Make Your Web Delicious!</Text>
          </Title>
          <Description>
            <Text>
              JavaScript UI library and free open source project constantly managed by NHN Cloud
            </Text>
          </Description>
        </Slogan>
      </Wrapper>
      <MobileLink />
    </>
  );
};

export default HeroSectionComponent;
