import styled from 'styled-components';
import React from 'react';

interface IntroProps {
  description: string;
}

const Wrapper = styled.div`
  width: 420px;

  @media (max-width: 1200px) {
    width: 315px;
  }

  @media (max-width: 768px) {
    width: 560px;
  }
`;

const Header = styled.h2`
  font-size: 42px;
  color: #222;
  margin: 0 0 10px;

  @media (max-width: 1200px) {
    font-size: 30px;
  }
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 30px;
  color: #555;
  margin: 0;

  @media (max-width: 1200px) {
    font-size: 13px;
    line-height: 22px;
  }
`;

const Intro: React.FC<IntroProps> = ({ description }) => {
  return (
    <Wrapper>
      <Header>Components</Header>
      <Description>{description}</Description>
    </Wrapper>
  );
};

export default Intro;
