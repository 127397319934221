import React from 'react';
import styled from 'styled-components';

import { ProductInfo } from '@t/components';
import Icons from '@/components/main/component/Icons';
import Intro from '@/components/main/component/Intro';

interface ComponentSectionProps {
  items: ProductInfo[];
  description: string;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  padding: 100px 0;

  @media (max-width: 1200px) {
    padding: 80px 0;
  }

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  width: 62.5%;

  @media (max-width: 1200px) {
    max-width: 900px;
    width: 75%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 65%;
  }
`;

const ComponentSection: React.FC<ComponentSectionProps> = ({ items, description }) => {
  const iconItems = items.map(({ id, name }) => ({ id, name }));

  return (
    <Wrapper>
      <Content>
        <Intro description={description} />
        <Icons iconItems={iconItems} />
      </Content>
    </Wrapper>
  );
};

export default ComponentSection;
