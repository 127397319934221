import React from 'react';
import styled from 'styled-components';

import { ButtonItem, ApplicationIntroItem, ProductInfo, ApplicationId } from '@t/components';
import { getApplicationIconType } from '@/util';
import { MobilePreview, WebPreview } from '@/components/main/application/Preview';
import Intro from '@/components/main/application/Intro';
import { SeeMoreButton as ApplicationButton } from '@/components/main/common/SeeMoreButton';
import createGA from '@/components/common/createGA';

interface WrapperProps {
  index: number;
}

interface ApplicationProps {
  item: ProductInfo;
  index: number;
}

const Wrapper = styled.div<WrapperProps>`
  padding: 100px 0;
  display: flex;
  flex-direction: ${({ index }): string => (index % 2 ? 'row-reverse' : '')};
  align-items: center;
  justify-content: center;
  background-color: ${({ index }): string => (index % 2 ? '#f9f9f9' : '')};

  @media (max-width: 1200px) {
    padding: 75px 0;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 40px 0;
  }

  @media (max-width: 720px) {
    padding: 0 0 14%;
    margin: 0 auto;
  }
`;

const WebWrapper = styled.div<WrapperProps>`
  width: 62.5%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ index }): string => (index % 2 ? 'row-reverse' : '')};

  @media (max-width: 1200px) {
    width: 75%;
    max-width: 900px;
  }

  @media (max-width: 720px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const MobileWrapper = styled.div<WrapperProps>`
  width: 35%;
  max-width: 420px;
  margin-right: ${({ index }): string => (index % 2 ? '' : '40px')};
  margin-left: ${({ index }): string => (index % 2 ? '40px' : '')};

  @media (max-width: 1200px) {
    width: 26%;
    max-width: 315px;
    margin-right: ${({ index }): string => (index % 2 ? '' : '30px')};
    margin-left: ${({ index }): string => (index % 2 ? '30px' : '')};
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 555px;
    margin: 0;
    text-align: center;
  }

  @media (max-width: 720px) {
    max-width: none;
    width: 89%;

    & .see-more {
      position: relative;
      width: 50%;
      height: 0;
      padding-bottom: 13.6%;
    }
  }
`;

const buttonColors = ['#03bd9e', '#00a9ff', '#ff5883', '#ff6618', '#ffbc00'];

const Application: React.FC<ApplicationProps> = ({ item, index }) => {
  const { id } = item;
  const color = buttonColors[index];
  const introItem: ApplicationIntroItem = {
    index,
    item,
    color,
    type: getApplicationIconType(id as ApplicationId),
  };
  const buttonItem: ButtonItem = {
    url: `/${id}`,
    color,
    ga: createGA('Main - Application', 'click', 'Application button', id),
    testId: 'application-link-list',
  };

  return (
    <Wrapper index={index}>
      <WebWrapper index={index}>
        <MobileWrapper index={index}>
          <Intro introItem={introItem} />
          <MobilePreview id={id as ApplicationId} />
          <ApplicationButton buttonItem={buttonItem} />
        </MobileWrapper>
        <WebPreview id={id as ApplicationId} />
      </WebWrapper>
    </Wrapper>
  );
};

export default Application;
