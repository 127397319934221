import React from 'react';
import styled from 'styled-components';

import { ApplicationIntroItem } from '@t/components';
import { Icon } from '@/components/common/Icon';

interface NameColorProps {
  nameColor: string;
}

interface IntroProps {
  introItem: ApplicationIntroItem;
}

const Intro = styled.div`
  @media (max-width: 768px) {
    margin-top: 40px;
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 720px) {
    margin: 30px 0;
  }
`;

const Header = styled.div`
  height: 65px;
  display: flex;
  align-items: center;
  margin-left: -10px;

  @media (max-width: 1200px) {
    height: 45px;
    margin-left: -5px;
  }

  @media (max-width: 720px) {
    height: 45px;
    margin: 0;
  }
`;

const ApplicationIcon = styled(Icon)`
  width: 60px;
  height: 60px;

  @media (max-width: 1200px) {
    width: 45px;
    height: 45px;
  }

  @media (max-width: 720px) {
    width: 40px;
    height: 40px;
    background-size: 1200px;
  }
`;

const Name = styled.div<NameColorProps>`
  display: inline-block;
  font-weight: bold;
  margin-left: 15px;
  font-size: 42px;

  color: ${({ nameColor }): string => nameColor};

  @media (max-width: 1200px) {
    margin-left: 5px;
    font-size: 30px;
  }

  @media (max-width: 720px) {
    font-size: 21px;
  }
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 30px;
  color: #555;
  margin: 20px 0;

  @media (max-width: 1200px) {
    font-size: 13px;
    line-height: 22px;
    margin: 15px 0 30px;
  }

  @media (max-width: 768px) {
    text-align: left;
    margin-bottom: 30px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

const MobileDescription = styled.div`
  display: none;
  color: #555;
  margin-top: 20px;
  font-size: 15px;
  line-height: 28px;
  text-align: left;

  @media (max-width: 720px) {
    display: block;
    word-break: break-all;
  }
`;

const IntroComponent: React.FC<IntroProps> = ({ introItem }) => {
  const { item, color, type } = introItem;
  const { name, detailDesc, description } = item;

  return (
    <Intro>
      <Header>
        <ApplicationIcon iconType={type} />
        <Name nameColor={color} data-testid="application-name-list">
          {name}
        </Name>
      </Header>
      <Description>{detailDesc}</Description>
      <MobileDescription>{description}</MobileDescription>
    </Intro>
  );
};

export default IntroComponent;
