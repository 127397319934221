import React from 'react';
import styled from 'styled-components';

import { postUrl } from '@/constant';
import { BlockLink } from '@/components/common/Link';
import createGA from '@/components/common/createGA';

interface TagProps {
  tag: string;
  language: 'ko' | 'en';
}

const LinkContent = styled.div`
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  color: #222;

  &:hover {
    border: 1px solid #b0b6ff;
    background-color: #edeeff;
  }

  @media (max-width: 1200px) {
    height: 23px;
    line-height: 23px;
    border-radius: 4px;
    font-size: 12px;
  }

  @media (max-width: 720px) {
    border-radius: 4px;
    height: auto;
    font-size: 11px;
    line-height: 2;
    padding: 0 8px;
  }
`;

const Tag: React.FC<TagProps> = ({ tag, language }) => {
  const prefix = `${postUrl[language.toUpperCase() as 'KO' | 'EN']}`;
  const ga = createGA('Main - Posts', 'click', 'Post Tag', tag);

  return (
    <BlockLink type="internal" url={`${prefix}/${tag}`} ga={ga}>
      <LinkContent>{`#${tag}`}</LinkContent>
    </BlockLink>
  );
};

export default Tag;
