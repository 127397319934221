import React from 'react';
import styled from 'styled-components';

import { ProductInfo, ToolIconItem } from '@t/components';
import { IconType } from '@t/styles';
import { Icon } from '@/components/common/Icon';
import { BlockLink } from '@/components/common/Link';
import createGA from '@/components/common/createGA';

interface ToolSectionProps {
  items: ProductInfo[];
}

interface ToolsProps {
  items: ToolIconItem[];
}

const Wrapper = styled.div`
  padding: 90px 0 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    padding: 70px 0 105px;
  }

  @media (max-width: 768px) {
    height: 427px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

const HeaderArea = styled.div`
  max-width: 1200px;
  width: 62.5%;
  margin-bottom: 60px;

  @media (max-width: 1200px) {
    max-width: 900px;
    width: 75%;
    margin-bottom: 45px;
  }

  @media (max-width: 768px) {
    width: 560px;
    margin-bottom: 30px;
  }
`;

const Header = styled.h2`
  font-size: 42px;
  color: #222;
  margin: 0;

  @media (max-width: 1200px) {
    font-size: 30px;
  }
`;

const ToolArea = styled.div`
  max-width: 1200px;
  width: 62.5%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  & a {
    margin-left: 40px;
    width: 100%;
  }

  & a:first-child {
    margin: 0;
  }

  @media (max-width: 1200px) {
    max-width: 900px;
    width: 75%;

    & a {
      margin-left: 30px;
    }
  }

  @media (max-width: 768px) {
    max-width: 550px;

    & a {
      margin-left: 20px;
    }
  }
`;

const Tool = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover h3 {
    color: #515ce6;
  }
`;

const ToolIcon = styled(Icon)`
  width: 190px;
  height: 190px;
  margin-bottom: 15px;

  @media (max-width: 1200px) {
    width: 135px;
    height: 135px;
    margin-bottom: 10px;
  }
`;

const Name = styled.h3`
  font-size: 18px;
  font-weight: bold;
  color: #222;
  margin: 0;
  margin-bottom: 5%;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

const Description = styled.p`
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  color: #777;
  margin: 0;
  width: 100%;

  @media (max-width: 1200px) {
    font-size: 13px;
    line-height: 22px;
  }
`;

const Tools: React.FC<ToolsProps> = ({ items }) => {
  const ga = createGA('Main - Tool', 'click', 'Tool icon');

  return (
    <ToolArea>
      {items.map(({ id, name, link, description }) => {
        const type = `${name.toUpperCase().replace(/ /gi, '_')}_BI` as IconType;

        return (
          <BlockLink
            type="external"
            url={link}
            ga={{ ...ga, label: `Tool icon - ${name}` }}
            key={id}
            testId="tool-list"
          >
            <Tool>
              <ToolIcon iconType={type} />
              <Name data-testid="tool-name-list">{name}</Name>
              <Description data-testid="tool-description-list">{description}</Description>
            </Tool>
          </BlockLink>
        );
      })}
    </ToolArea>
  );
};

const ToolSection: React.FC<ToolSectionProps> = ({ items }) => {
  const toolItems = items.map(({ id, name, link, description }) => ({
    id,
    name,
    link,
    description,
  })) as ToolIconItem[];

  return (
    <Wrapper>
      <HeaderArea>
        <Header>Tools</Header>
      </HeaderArea>
      <Tools items={toolItems} />
    </Wrapper>
  );
};

export default ToolSection;
