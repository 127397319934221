import React from 'react';
import styled from 'styled-components';

import { InlineBlockLink } from '@/components/common/Link';
import createGA from '@/components/common/createGA';

interface TitleProps {
  title: string;
  url: string;
}

const Wrapper = styled.div`
  width: 100%;
  margin-left: 15px;
  text-overflow: ellipsis;

  @media (max-width: 720px) {
    margin: 8px 0 5px;
  }
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #222;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1200px) {
    font-size: 13px;
  }

  @media (max-width: 720px) {
    font-size: 16px;
    line-height: 2;
    font-weight: bold;
  }
`;

const PostTitle: React.FC<TitleProps> = ({ title, url }) => {
  const ga = createGA('Main - Posts', 'click', 'post', title);

  return (
    <Wrapper>
      <InlineBlockLink type="internal" url={url} ga={ga}>
        <Title>{title}</Title>
      </InlineBlockLink>
    </Wrapper>
  );
};

export default PostTitle;
