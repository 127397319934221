import React from 'react';
import styled from 'styled-components';

import { PostItem } from '@t/components';
import { isKorean } from '@/util';
import Tag from '@/components/main/document/Tag';
import Title from '@/components/main/document/Title';
import Date from '@/components/main/document/Date';

interface PostItemProps {
  item: PostItem;
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 15px 0;

  @media (max-width: 720px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
  }
`;

const Item: React.FC<PostItemProps> = ({ item }) => {
  return (
    <Wrapper data-testid="post-list">
      <Tag tag={item.tag} language={isKorean ? 'ko' : 'en'} />
      <Title title={item.title} url={item.url} />
      <Date date={item.date} />
    </Wrapper>
  );
};

export default Item;
